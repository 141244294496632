@import '../../../styles/helpers';

.Header {
  display: flex;
  flex-direction: column-reverse;
  padding-top: $spacing-medium;
  padding-left: $spacing-medium;
  padding-right: $spacing-medium;
}

.Header--titleFirst {
  flex-direction: column;
}

.BackLink {
  margin-bottom: $spacing-medium;
}

.Title__Container {
  display: flex;
  flex-direction: row;
  margin-bottom: $spacing-medium;
}

.SubTitle {
  font-size: getTypeScale(f5);
  text-transform: uppercase;
  margin-right: $spacing-small-1q;
  margin-top: $spacing-extra-small-2px;
}

.NonAuthWrapper {
  border-bottom: 1px solid $mid-gray;
  margin-bottom: $spacing-medium;
  margin-left: -#{$spacing-medium};
  margin-right: -#{$spacing-medium};
  padding-left: $spacing-medium;
  padding-right: $spacing-medium;
}

.BackLink__LinkLabel {
  text-decoration: none;
  font-size: getTypeScale(f6);
}

.BackLink__ButtonLabel {
  text-decoration: none;
  font-size: getTypeScale(f6);
}

@include applyThemes {
  .Title {
    color: getThemeColor(pageheader-fg);
  }

  .BackLink__LinkLabel {
    color: getThemeColor(pageheader-link, $dark-med-dark-gray);
  }
}
